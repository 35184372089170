import { DIRECTIONS } from '../../../common/react-with-direction';
import { GAP_WIDTH, MOVEMENT_BUTTON_WIDTH } from '.';

export const getHowManyItemsCanBeShown = (
  itemsListContainer: HTMLElement,
  itemsList: HTMLUListElement,
  startingPoint: number,
) => {
  const itemsListContainerWidth = itemsListContainer.offsetWidth;
  const [_, shownItemsWidth, itemsShown] = (Array.from(itemsList.children) as HTMLElement[])
    .filter((item, index) => index >= startingPoint)
    .reduce(
      (prev, item, index) => {
        const [totalWidth, shownItemsWidth, itemsShown] = prev;
        const itemWidth = item.offsetWidth;

        const isLastElement = index === itemsList.children.length - 1;
        const additionalGap = isLastElement ? 0 : GAP_WIDTH;

        const totalWidthsSum = totalWidth + itemWidth + additionalGap;
        const doesElementFit = totalWidthsSum <= itemsListContainerWidth;

        const updatedShownItemsWidth = doesElementFit
          ? shownItemsWidth + itemWidth + additionalGap
          : shownItemsWidth;
        const updatedItemsShown = doesElementFit ? itemsShown + 1 : itemsShown;

        return [totalWidthsSum, updatedShownItemsWidth, updatedItemsShown];
      },
      [0, 0, 0],
    );
  const isShownAll = isListFullyShown(
    startingPoint,
    startingPoint + itemsShown,
    itemsList.children.length,
  );

  if (!isShownAll && shownItemsWidth > itemsListContainerWidth - MOVEMENT_BUTTON_WIDTH) {
    return Math.max(itemsShown - 1, 1);
  }

  return Math.max(itemsShown, 1);
};

export const isListFullyShown = (from: number, to: number, itemsAmount: number) =>
  to - from === itemsAmount;

export const moveListTo =
  (direction: DIRECTIONS, itemsList: HTMLUListElement) => (moveToIndex: number) => {
    const indexElement = itemsList.children[moveToIndex] as HTMLElement;
    const firstElement = itemsList.children[0] as HTMLElement;
    const isRtl = direction === DIRECTIONS.RTL;

    const indexElementPosition = indexElement.offsetLeft + (isRtl ? indexElement.offsetWidth : 0);
    const firstElementPosition = firstElement.offsetLeft + (isRtl ? firstElement.offsetWidth : 0);
    const position = firstElementPosition - indexElementPosition;
    itemsList.style.transform = `translateX(${position}px)`;
  };

export const setRef =
  <T>(setRef: React.Dispatch<React.SetStateAction<T>>) =>
  (node: T) => {
    if (!node) {
      return;
    }
    setRef(node);
  };
